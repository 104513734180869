import { createChatBotMessage } from 'react-chatbot-kit';
import CustomOptions from './CustomOptions';
import CustomBotAvatar from './CustomBotAvatar.jsx';
import PaymentLinkWidget from './paymentLinkWidget.js';

const config = {
  botName: 'PetzAdoreBot',
  initialMessages: [
    createChatBotMessage(
      'Hi. Welcome to Petzadore Chat. Just share a few details about your pet, and we will help you find the perfect pet care package for your needs! Your information will not be shared with any other third party',
      {
        widget: 'options',
      }
    ),
  ],
  customStyles: {
    botMessageBox: {
      backgroundColor: '#bd0502',
    },
    chatButton: {
      backgroundColor: '#bd0502',
    },
  },
  customComponents: {
    botAvatar: (props) => <CustomBotAvatar {...props} />,
  },
  widgets: [
    {
      widgetName: 'options',
      widgetFunc: (props) => <CustomOptions {...props} />,
    },
    {
      widgetName: 'paymentLink',
      widgetFunc: (props) => <PaymentLinkWidget {...props} />,
    },
  ],
};

export default config;
