import React from 'react';
import './CustomOptions.css';

const CustomOptions = (props) => {
  const options = props?.payload?.buttonOptions || [
    { text: 'New Insurance', value: 'New Insurance', id: 1 },
    { text: 'Have Quote Ref. No.', value: 'Have Quote Ref. No.', id: 2 },
    { text: 'Claims', value: 'Claims', id: 3 },
  ];

  const handleClick = (value) => {
    props.actionProvider.addMessage({
      text: options.find((option) => option.value === value).text,
      isUser: true,
    });

    setTimeout(() => {
      props.actionProvider.handleFlowSelection(value);
    }, 500);
  };

  return (
    <div className='options-container'>
      <div className='info-label'>Choose an option:</div>
      {options.map((option) => (
        <button
          key={option.id}
          onClick={() => handleClick(option.value)}
          className='option-button'
        >
          {option.text}
        </button>
      ))}
    </div>
  );
};

export default CustomOptions;
