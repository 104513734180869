import React, { useEffect, useState } from "react";
import { Chatbot } from "react-chatbot-kit";
import useChatMessages from "./useChatMessages";
import { Button, Drawer } from "antd";
import "react-chatbot-kit/build/main.css";
import config from "./config";
import ActionProvider from "./actionProvider";
import ErrorBoundary from "./ErrorBoundary";

const disableScroll = () => {
  document.body.style.overflow = "hidden";
  document.body.style.position = "fixed";
  document.body.style.width = "100%";
};

const enableScroll = () => {
  document.body.style.overflow = "";
  document.body.style.position = "";
  document.body.style.width = "";
};

const ChatbotComponent = (props) => {
  const [open, setOpen] = useState(false);

  const showDrawer = () => {
    setOpen(true);
    disableScroll();
  };

  const onClose = () => {
    setOpen(false);
    enableScroll();
  };

  const { messages, addMessage } = useChatMessages();
  const handleUserMessage = (message) => {
    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      addMessage({ text: message, isUser: true });
    }
  };

  useEffect(() => {
    try {
      localStorage.clear();
    } catch (error) {
      console.error("Failed to clear localStorage:", error);
    }
  }, []);

  const { MessageParser } = props;

  return (
    <ErrorBoundary>
      <div>
        <Button type="primary" className="floating-chat" onClick={showDrawer}>
          <i className="fa-regular fa-comment-dots"></i>
        </Button>

        <Drawer
          width={500}
          title={
            <p>
              Conversation with <span>PetzAdoreBot</span>
            </p>
          }
          className="chat-bot-container"
          onClose={onClose}
          open={open}
        >
          <Chatbot
            config={config}
            actionProvider={ActionProvider}
            messageParser={MessageParser}
            handleUserMessage={handleUserMessage}
            headerText={false}
          />

          <div>
            {messages.map((message, index) => (
              <div key={index}>{message.text}</div>
            ))}
          </div>
        </Drawer>
      </div>
    </ErrorBoundary>
  );
};

export default ChatbotComponent;
