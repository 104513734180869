import React, { useEffect } from 'react';
import { Row, Col, message } from "antd";
import {
  Button,
  Card,
  Input,
  ButtonType,
  InputType,
  HtmlType,
  Modal,
  Form,
  Checkbox,
  Space,
  Icon,
  Divider,
  Flex,
} from "antd";
import { useNavigate } from "react-router-dom";
import Axios from './interceptor';
import { isEnabledByRole } from '../Common/roleAnalyser';

function AdminSettings() {
  const navigate = useNavigate();
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    axios.put('settings', values).then((response) => {
      if (response?.data) {
        message.success(`Successfully updated`);
        setTimeout(() => {
          navigate(0);
        }, 2000);
      }

    }).catch(err => {
      console.error(err);
      message.error(`Unable to get smtp details`);
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  useEffect(() => {
    axios.get('settings').then((response) => {
      if (response?.data) {
        if (response.data.settings !== "") {
          console.log('Fill the fields', response.data.settings);
          const { appName,
            metaKeywords,
            appSupportemail,
            mobileNumber,
            facebookLink,
            twitterLink,
            linkedinLink,
            instagramLink,
            applestoreLink,
            playstoreLink,
            copyrightText } = response.data.settings
          form.setFieldsValue({
            appName,
            metaKeywords,
            appSupportemail,
            mobileNumber,
            facebookLink,
            twitterLink,
            linkedinLink,
            instagramLink,
            applestoreLink,
            playstoreLink,
            copyrightText
          });
        }
      }
    }).catch(err => {
      console.error(err);
      message.error(`Unable to get smtp details`);
    });

  }, []);

  const initialValues = {};
  return (
    <>
    
      <Row justify="center">
        <Col span={12}>
          <Card title="App Settings">
            <Form form={form} initialValues={initialValues} onFinish={onFinish}
              onFinishFailed={onFinishFailed} autoComplete="off" layout="vertical">
              <Form.Item label="App Name" name="appName"
                rules={[
                  { required: true, message: 'Please input App Name' }
                ]}>
                <Input />
              </Form.Item>

              <Form.Item label="Meta keywords" name="metaKeywords"
                rules={[
                  { required: true, message: 'Please input Meta Keywords' }
                ]}>
                <Input />
              </Form.Item>

              <Form.Item label="App support email" name="appSupportemail"
                rules={[
                  { required: true, message: 'Please input App email' }
                ]}>
                <Input />
              </Form.Item>

              <Form.Item label="Mobile number" name="mobileNumber"
                rules={[
                  { required: true, message: 'Please input Mobile number' }
                ]}>
                <Input />
              </Form.Item>

              <Form.Item label="Copyrights text" name="copyrightText"
                rules={[
                  { required: true, message: 'Please input Copyrights text' }
                ]}>
                <Input />
              </Form.Item>

              <h3>Social Networks</h3>

              <Form.Item label="Facebook" name="facebookLink"
                rules={[
                  { required: true, message: 'Please input facebook details' }
                ]}>
                <Input />
              </Form.Item>

              <Form.Item label="Twitter" name="twitterLink"
                rules={[
                  { required: true, message: 'Please input twitter details' }
                ]}>
                <Input />
              </Form.Item>

              <Form.Item label="Linkedin" name="linkedinLink"
                rules={[
                  { required: true, message: 'Please input Linked in details' }
                ]}>
                <Input />
              </Form.Item>

              <Form.Item label="Instagram" name="instagramLink"
                rules={[
                  { required: true, message: 'Please input Instagram details' }
                ]}>
                <Input />
              </Form.Item>

              <h3>App Links</h3>

              <Form.Item label="Apple store " name="applestoreLink"
                rules={[
                  { required: true, message: 'Please input apple store details' }
                ]}>
                <Input />
              </Form.Item>

              <Form.Item label="Play store " name="playstoreLink"
                rules={[
                  { required: true, message: 'Please input Play store details' }
                ]}>
                <Input />
              </Form.Item>

              <Flex align="center" justify="center" gap="small">
                <Button onClick={() => navigate(-1)}>Back</Button>
                {isEnabledByRole('settings-updatesettings') &&
                  <Button type="primary" htmlType="submit">
                    Submit
                  </Button>
                }
              </Flex>

            </Form>
          </Card>
        </Col>
      </Row>

    </>
  );
}

export default AdminSettings;
