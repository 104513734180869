import React from "react";
import { Row, Col, message } from "antd";
import { Button, Card, Input, Form } from "antd";
import Axios from "./interceptor";
import { useNavigate,Link } from "react-router-dom";
import { MdEmail } from "react-icons/md";

function ResetPassword() {
  const navigate = useNavigate();
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);

  const onFinish = (values) => {
    const { emailId } = values;
    const body = { emailId };
    axios
      .post("resetpassword", body)
      .then((response) => {
        if (response) {
          console.log("response :: ", response);
          message.success(
            `Successfully reset Password. Please check you Email`
          );
          localStorage.clear();
          navigate("/");
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const initialValues = {
    remember: true,
    username: "ravisuper",
    password: "03611a0433",
  };
  return (
    <Form
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Row
        style={{
          minHeight: "100vh",
          justifyContent: "center",
          background: "#eee",
        }}
      >
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <Card
            title="Reset Password"
            style={{ width: "100%", maxWidth: "560px" }}
          >
            <Form.Item
              label="Email"
              name="emailId"
              rules={[{ required: true, message: "Please enter your Email" }]}
            >
              <Input placeholder="Email" prefix={<MdEmail />} />
            </Form.Item>

            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>

            <Button block style={{ marginTop: "20px" }}>
          
               <Link to="/login" style={{ marginTop: "20px" }}>
              Back
              </Link>
            </Button>
          </Card>
        </Col>
      </Row>
    </Form>
  );
}

export default ResetPassword;
