import React, { useState } from 'react';
import { Row, Col, message } from 'antd';
import { Button, Card, Input, Select, InputType, HtmlType, Modal, Form, Checkbox, Space, Icon } from 'antd';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { useNavigate } from "react-router-dom";
import Axios from './interceptor';

function UpdateProfile() {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);
  const [user, setUser] = useState(null);

  useState(() => {
    axios.get('accountprofile').then((response) => {
      if (response?.data?.user) {
        console.log(response.data.user);
        setUser(response.data.user);
        form.setFieldsValue({
          firstname: response.data.user.firstname,
          lastname: response.data.user.lastname,
          username: response.data.user.userName,
          usertype: response.data.user.userType.toString(),
        });
      }
    }).catch(err => {
      // message.error(`Unable to save role.`);
      console.error(err);
    });
  }, [])

  const onFinish = (values) => {
    axios
      .put("user/" + user._id, values)
      .then((response) => {
        if (response) {
          navigate("/admin");
        }
      })
      .catch((err) => {
        message.error(`Update settings failed.`);
        console.error(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };
  const handleChange = (value) => {
    console.log(value);
    const nuser = { ...user, userType: value }
    setUser(nuser);
  };

  return (
    <Form form={form} onFinish={onFinish}
      onFinishFailed={onFinishFailed} autoComplete="off" layout="vertical">
      <Row
        style={{
          minHeight: "80vh",
          justifyContent: "center",
          background: "#eee"
        }}
      >
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >

          <Card title="Update Profile" style={{ width: "100%", maxWidth: "560px" }}>
            <Form.Item
              label="First Name"
              name="firstname"
              rules={[
                { required: true, message: 'Please input FirstName' },
                { min: 4, message: 'FirstName must be minimum 4 characters.' },
                { max: 16, message: 'FirstName cannot exceed 16 characters.' }
              ]}>
              <Input placeholder='Firstname' />
            </Form.Item>

            <Form.Item
              label="Last Name"
              name="lastname"
              rules={[
                { required: true, message: 'Please input LastName' },
                { min: 4, message: 'LastName must be minimum 4 characters.' },
                { max: 16, message: 'LastName cannot exceed 16 characters.' }
              ]}
            >
              <Input placeholder='Last Name' />
            </Form.Item>

            <Form.Item
              label="User Name"
              name="username"
              rules={[
                { required: true, message: 'Please input UserName' },
                { min: 4, message: 'UserName must be minimum 4 characters.' },
                { max: 16, message: 'UserName cannot exceed 16 characters.' }
              ]}
            >
              <Input placeholder='UserName' />
            </Form.Item>

            <Form.Item
              label="User Type"
              name="usertype"
            >
              <Select
                defaultValue={"'" + user?.userType + "'" ?? "1"}
                style={{ width: 500 }}
                onChange={handleChange}
                options={[
                  { value: "1", label: 'SuperAdmin' },
                  { value: "2", label: 'Admin' },
                  { value: "3", label: 'Manager' },
                  { value: "4", label: 'Executive' },
                ]}
              />
            </Form.Item>
            <Button type='primary' htmlType="submit" block>
              Submit
            </Button>
            <Button block onClick={() => navigate(-1)} style={{ marginTop: '20px' }}>Back</Button>
          </Card>
        </Col>
      </Row>
    </Form>
  )
}

export default UpdateProfile