import React, { useEffect, useState } from "react";
import "../../src/styles.css";
import { Outlet, NavLink } from "react-router-dom";
import { Layout, Flex, Row, Col } from "antd";
import {
  AiFillHome,
  AiOutlineDesktop,
  AiOutlineTeam,
  AiFillSketchCircle,
  AiOutlineDatabase,
  AiOutlineDotChart,
  AiOutlineSecurityScan,
  AiOutlineSetting,
  AiOutlineUser,
  AiOutlineCrown,
  AiOutlineUsergroupAdd,
  AiOutlineAudit,
} from "react-icons/ai";
import { TbUserShare } from "react-icons/tb";
import { GrDocumentPerformance } from "react-icons/gr";
import { MdOutlinePolicy, MdOutlinePets, MdAltRoute } from "react-icons/md";

import { PiCurrencyInrBold } from "react-icons/pi";
import UserMenu from "../Common/user_menu";
const { Content } = Layout;

function generateIcons(icon) {
  switch (icon) {
    case "AiFillHome":
      return <AiFillHome></AiFillHome>;
    case "AiOutlineDesktop":
      return <AiOutlineDesktop></AiOutlineDesktop>;
    case "AiOutlineTeam":
      return <AiOutlineTeam></AiOutlineTeam>;
    case "AiFillSketchCircle":
      return <AiFillSketchCircle></AiFillSketchCircle>;
    case "AiOutlineDatabase":
      return <AiOutlineDatabase></AiOutlineDatabase>;
    case "AiFillHome":
      return <AiOutlineDotChart></AiOutlineDotChart>;
    case "AiOutlineSecurityScan":
      return <AiOutlineSecurityScan></AiOutlineSecurityScan>;
    case "AiOutlineSetting":
      return <AiOutlineSetting></AiOutlineSetting>;
    case "AiOutlineDotChart":
      return <AiOutlineDotChart></AiOutlineDotChart>;
    case "AiOutlineCrown":
      return <AiOutlineCrown />;
    case "AiOutlineUsergroupAdd":
      return <AiOutlineUsergroupAdd />;
    case "AiOutlineAudit":
      return <AiOutlineAudit />;
    case "PiCurrencyInrBold":
      return <PiCurrencyInrBold />;
    case "MdOutlinePolicy":
      return <MdOutlinePolicy />;
    case "MdOutlinePets":
      return <MdOutlinePets />;
    case "TbUserShare":
      return <TbUserShare />;
    case "GrDocumentPerformance":
      return <GrDocumentPerformance />;
    default:
      return <></>;
  }
}

const Layout_Template = () => {
  const permissions = localStorage.getItem("permissions")?.split(",");
  const userType = localStorage.getItem("userType");
  const [menuItems, setMenuItems] = useState();
  useEffect(() => {
    const items = [
      {
        key: "insurermanagement-readinsurer",
        href: "/insurer",
        title: "Insurer Management",
        icon: "AiOutlineUsergroupAdd",
        isActive: false,
      },
      {
        key: "enquiry-readenquiry",
        href: "/enquiry",
        title: "Enquiry",
        icon: "AiOutlineSetting",
        isActive: false,
      },
      {
        key: "proposal",
        href: "/proposal",
        title: "Proposal",
        icon: "GrDocumentPerformance",
        isActive: false,
      },
      {
        key: "policy-stauts",
        href: "/policy-status",
        title: "Policies Issued",
        icon: "MdOutlinePolicy",
        isActive: false,
      },
      {
        key: "partners",
        href: "/partners",
        title: "Partners",
        icon: "MdOutlinePets",
        isActive: false,
      },
      {
        key: "pricematrixmanagement-readmatrix",
        href: "/price-matrix",
        title: "Price Matrix",
        icon: "PiCurrencyInrBold",
        isActive: false,
      },
      {
        key: "referral-settings",
        href: "/referral-settings",
        title: "Referral Settings",
        icon: "TbUserShare",
        isActive: false,
      },
      {
        key: "adminmanagement-readadmin",
        href: "/admin",
        title: "Admin",
        icon: "AiOutlineSecurityScan",
        isActive: false,
      },
      {
        href: "/roles",
        title: "RoleManagement",
        icon: "AiOutlineCrown",
        isActive: false,
      },

      {
        key: "activitylogs-readactivities",
        href: "/activity-log",
        title: "Activity Log",
        icon: "AiOutlineSetting",
        isActive: false,
      },
      {
        key: "settings-readsettings",
        href: "/settings",
        title: "Settings",
        icon: "AiOutlineSetting",
        isActive: false,
      },
      // {
      //   key: "quotemanagement-readquote",
      //   href: "/quote",
      //   title: "Quote Management",
      //   icon: "AiOutlineAudit",
      //   isActive: false,
      // },

      // {
      //   key: "smtpmanagement-readsmtp",
      //   href: "/smtp",
      //   title: "SMTP",
      //   icon: "AiOutlineDotChart",
      //   isActive: false,
      // },
    ].filter((x) => {
      if (userType === "1") return true;

      const keysplit = x?.key?.split("|");
      const duplicates = permissions?.filter((element) =>
        keysplit?.includes(element)
      );
      return duplicates?.length > 0;
    });

    setMenuItems(items);
  }, []);

  // console.log(userType);
  // console.log(menuItems);

  return (
    <>
      <header className="header-admin">
        <div className="logo">
          <img src="/images/logo.png" alt="logo" />
        </div>
        <UserMenu></UserMenu>
      </header>
      {/* header */}
      <Layout className="page-layout">
        <div className="sidebar">
          <ul className="side-naivation">
            {menuItems?.map((x) => {
              return (
                <li key={x.key || "defaultKey"}>
                  <NavLink
                    to={x.href}
                    className={({ isActive }) =>
                      isActive ? "active" : "inactive"
                    }
                  >
                    <span className="icon">{generateIcons(x.icon)}</span>
                    <span className="item">{x.title}</span>
                  </NavLink>
                </li>
              );
            })}
          </ul>
        </div>
        <Content className="side-content">
          <Outlet />
        </Content>
      </Layout>
    </>
  );
};
export default Layout_Template;
