import React from "react";
import { useState } from "react";
import { Row, Col, message } from "antd";
import {
  Button,
  Card,
  Input,
  ButtonType,
  InputType,
  HtmlType,
  Modal,
  Form,
  Checkbox,
  Space,
  Icon,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Axios from "./interceptor";
import { useNavigate } from "react-router-dom";

function ChangePassword() {
  const navigate = useNavigate();
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);

  const onFinish = (values) => {
    const { oldpassword, newpassword } = values;
    const body = { oldpassword, newpassword };
    axios
      .post("changepassword", body)
      .then((response) => {
        if (response) {
          console.log("response :: ", response);
          message.success(`Successfully changed Password.`);
          localStorage.clear();
          navigate("/");
        }
      })
      .catch((err) => {
        console.error(err);
        message.error(`Couldnot change password.`);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  const initialValues = {
    remember: true,
    username: "ravisuper",
    password: "03611a0433",
  };
  return (
    <Form
      initialValues={initialValues}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
      layout="vertical"
    >
      <Row
        style={{
          minHeight: "100vh",
          justifyContent: "center",
          background: "#eee",
        }}
      >
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flex: 1,
          }}
        >
          <Card
            title="Change Password"
            style={{ width: "100%", maxWidth: "560px" }}
          >
            <Form.Item
              label="Old Password"
              name="oldpassword"
              rules={[
                { required: true, message: "Please input old Password!" },
                { min: 8, message: "Password must be minimum 8 characters." },
                { max: 16, message: "Password cannot exceed 16 characters." },
              ]}
            >
              <Input.Password
                placeholder="Old Password"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <Form.Item
              label="New Password"
              name="newpassword"
              rules={[
                { required: true, message: "Please input new Password!" },
                {
                  min: 8,
                  message: "NewPassword must be minimum 8 characters.",
                },
                {
                  max: 16,
                  message: "NewPassword cannot exceed 16 characters.",
                },
              ]}
            >
              <Input.Password
                placeholder="New Password"
                prefix={<LockOutlined />}
              />
            </Form.Item>

            <Form.Item
              label="Confirm Password"
              name="cnfpassword"
              dependencies={["newpassword"]}
              rules={[
                { required: true, message: "Please input confirm Password!" },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("newpassword") === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      new Error(
                        "The new password that you entered do not match!"
                      )
                    );
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder="Confirm Password"
                prefix={<LockOutlined />}
              />
            </Form.Item>
            <Button type="primary" htmlType="submit" block>
              Submit
            </Button>
            <Button
              block
              onClick={() => navigate(-1)}
              style={{ marginTop: "20px" }}
            >
              Back
            </Button>
          </Card>
        </Col>
      </Row>
    </Form>
  );
}

export default ChangePassword;
