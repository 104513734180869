import React from 'react';
import { UserOutlined, SettingOutlined, LockOutlined, LogoutOutlined } from '@ant-design/icons';
import { AiOutlineUser } from 'react-icons/ai';
import { useNavigate } from "react-router-dom";
import { Dropdown, Space } from 'antd';

function UserMenu() {
  const navigate = useNavigate();
  const onLogout = () => {
    localStorage.clear();
    navigate('/login');
  }

  const items = [
    {
      key: '1',
      label: (
        <a target="_blank" rel="noopener noreferrer" href="/myprofile">
          My Profile
        </a>
      ),
      icon: <SettingOutlined />,
    },
    {
      key: '2',
      label: (
        <a rel="noopener noreferrer" href="/changepassword">
          Change Password
        </a>
      ),
      icon: <LockOutlined />
    },
    {
      key: '3',
      label: (
        <a rel="noopener noreferrer" href="/updateprofile">
          Account Settings
        </a>
      ),
      icon: <SettingOutlined />,
    },
    {
      key: '4',
      label: (
        <a onClick={onLogout}>
          logout
        </a>
      ),
      icon: < LogoutOutlined />

    }
  ];


  return (
    <Dropdown menu={{ items }}>
      <a onClick={(e) => e.preventDefault()}>
        <AiOutlineUser />
      </a>
    </Dropdown>
  )
}

export default UserMenu