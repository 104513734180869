import React, { useEffect, useState } from "react";
import { Flex, Button, Table, Modal, Space, message } from "antd";
import { AiFillEdit, AiFillDelete } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import Axios from '../Common/interceptor';
import { isEnabledByRole } from '../Common/roleAnalyser';

function ActivityLog() {
  const navigate = useNavigate();
  const { confirm } = Modal;
  const [activities, setActivities] = useState(null);
  const axios = Axios(process.env.REACT_APP_NODE_ENDPOINT);

  useEffect(() => {
    axios
      .get("activitylog")
      .then((response) => {
        if (response?.data?.allActivitylogs) {
          const data = response?.data?.allActivitylogs.map(x => {
            return {
              key: x._id,
              userName: x.userName,
              log: x.log,
              clientIp: x.clientIp,
              clientPlatform: x.clientPlatform,
              createdAt: x.createdAt,
              ClientAgent: x.ClientAgent
            }
          })
          setActivities(data);
        }
      })
      .catch((err) => {
        message.error(`Unable to load Activities.`);
        console.error(err);
      });
  }, []);

  const showDeleteConfirm = (record) => {
    confirm({
      title: 'Are you sure delete this task?',
      content: 'Some descriptions',
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        axios
          .delete("activitylog/" + record.key)
          .then((response) => {
            message.success('Deleted Successfully');
            navigate(0);
          })
          .catch((err) => {
            message.error('Deleted failed');
            console.error(err);
          })
      },
      onCancel() {
        message.info("Delete cancelled");
      },
    });
  };

  const columns = [
    {
      title: "Username",
      dataIndex: "userName",
      key: "userName",
    },
    {
      title: "Log",
      dataIndex: "log",
      key: "log",
    },
    {
      title: "Client IP",
      dataIndex: "clientIp",
      key: "clientIp",
    },
    {
      title: "Client Agent",
      dataIndex: "ClientAgent",
      key: "ClientAgent",
    },
    {
      title: "Platform",
      dataIndex: "clientPlatform",
      key: "platform",
    },
    {
      title: "Date",
      key: "createdAt",
      dataIndex: "createdAt",
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => (
        <Space size="small">
          {isEnabledByRole('activitylogs-deleteactivities') &&
            <Button type="primary" danger onClick={() => showDeleteConfirm(record)}>
              Delete
            </Button>
          }
        </Space>
      ),
    },
  ];

  return (
    <>
      <Flex align="center" justify="space-between" style={{ marginBottom: 25 }}>
        <h2 style={{ margin: 0 }}>Activity Log</h2>
      </Flex>
      {isEnabledByRole('activitylogs-readactivities') &&
        <Table columns={columns} dataSource={activities} />
      }
    </>
  );
}
export default ActivityLog;