// import styles from './styles.module.css';
import React from "react";
import { message } from "antd";
import {
  Button,
  Input,
  Form,
  Checkbox,
} from "antd";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import Axios from "../src/Common/interceptor";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

function Login() {
  const navigate = useNavigate();
  const endPoint = process.env.REACT_APP_NODE_ENDPOINT;
  const axios = Axios(endPoint);

  const extractToken = async (token) => {
    const { userType, roleId } = jwtDecode(token);

    console.log(userType);
    localStorage.setItem("userType", userType);
    const endPoint = process.env.REACT_APP_NODE_ENDPOINT;
    const axios = Axios(endPoint);
    await axios
      .get("roles/" + roleId)
      .then((response) => {
        const permissions = response.data.user.permissions;
        console.log(permissions);
        localStorage.setItem("permissions", permissions);
      })
      .catch((err) => {
        message.error(`Unable to get Role.`);
        console.error(err);
      });
  };

  const onFinish = (values) => {
    const body = { userName: values.username, passWord: values.password };
    localStorage.clear();

    axios
      .post("login", body)
      .then(async (response) => {
        if (response?.data?.token) {
          localStorage.setItem("token", response.data.token);
          await extractToken(response.data.token);
          navigate("/admin");
        }
      })
      .catch((err) => {
        message.error(`Login failed. Check Username and Password.`);
        console.error(err);
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  return (
    <section
      className="login-section"
      style={{ backgroundImage: `url('/images/bg.jpg')` }}
    >
      <div className="login-box">
        <div className="login-img">
          <img src="images/logo.png" alt="logo" />
        </div>
        <Form
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label={false}
            name="username"
            rules={[{ required: true, message: "Please input your Username!" }]}
          >
            <Input placeholder="UserName" prefix={<UserOutlined />} />
          </Form.Item>

          <Form.Item
            label={false}
            name="password"
            rules={[{ required: true, message: "Please input your Password!" }]}
          >
            <Input.Password placeholder="Password" prefix={<LockOutlined />} />
          </Form.Item>

        <div className="rem-reset-flex">

          <Form.Item name="remember" valuePropName="checked" noStyle>
            <Checkbox>Remember me</Checkbox>
          </Form.Item>
          <Link to="/resetpassword">
            Reset Password
          </Link>
          </div>

          <div className="full-button">
            <Button type="primary" htmlType="submit">
              Log in
            </Button>
          </div>
        </Form>
        <div className="partner-reg">
          <Link to="/partner-with-us">
            Partner Registration
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Login;
