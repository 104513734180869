import axios from 'axios';

const axiosInstance = (endpoint) => {
    const axiosInstance = axios.create({
      baseURL: endpoint,
    });

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    // Modify the request config here (e.g., add headers, authentication tokens)
        const accessToken ='Bearer '+ localStorage.getItem("token");

    // ** If token is present add it to request's Authorization Header
    if (accessToken) {
      if (config.headers) config.headers.authorization = accessToken;
    }
    return config;
  },
  (error) => {
    // Handle request errors here

    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // Modify the response data here (e.g., parse, transform)

    return response;
  },
  (error) => {
    // Handle response errors here

    return Promise.reject(error);
  }
);

return axiosInstance;
}

export default axiosInstance;;
