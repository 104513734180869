class MessageParser {
  constructor(actionProvider) {
    this.actionProvider = actionProvider;
  }

  parse(message) {
    const trimmedMessage = message.trim();
    if (trimmedMessage) {
      this.actionProvider.reply(message);
    } else {
      alert("Message is empty, not sending to action provider");
    }
  }
}

export default MessageParser;
